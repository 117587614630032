@import "../../mixins";

.clients {
  & .swiper {
    @include mediaTablet {
      overflow: visible;
    }

    &-slide {
      height: rem(217);

      @include mediaBigDesktop {
        height: big(265);
      }

      @media (min-width: #{rem(2221)}){
        height: rem(265);
      }

      @include mediaTablet {
        height: rem(120);
      }
    }
  }

  &__item {
    padding: rem(30);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: var(--radius-common);

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      padding: big(30);

      border-width: big(1);
    }

    @include media(#{rem(880)}) {
      padding: rem(15);
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
  }
}
